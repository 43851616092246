import { Card } from './components/Card';
import { RiShoppingCart2Fill } from 'react-icons/ri';
import { SiWhatsapp } from 'react-icons/si';
import { RiRoadMapFill } from 'react-icons/ri';

export function App() {
  return (
    <div className='container mx-auto md:max-w-md py-2 md:py-6 px-7 bg-image' >
      <img
        className='w-56 mx-auto mb-10 border-4 border-primary-500 rounded-full bg-white mt-10 shadow-xl'
        src="https://lojamasterestofados.com.br/img/LOGO-MASTER-ESTOFADOS.cb2b1063.png" alt="" />

      <div className='grid gap-4'>
        <Card
          title="Compre online"
          link="https://lojamasterestofados.com.br/"
          icon={RiShoppingCart2Fill}
        />

        <Card
          title="Vendedor Oliveira"
          link="https://api.whatsapp.com/send?phone=5562984743501"
          icon={SiWhatsapp}
        />

        <Card
          title="Vendedor Luis"
          link="https://api.whatsapp.com/send?phone=5562992826976"
          icon={SiWhatsapp}
        />

        <Card
          title="Design de Interiores - Mayara"
          link="https://api.whatsapp.com/send?phone=5562985056875"
          icon={SiWhatsapp}
        />

        <Card
          title="Vendedor Junimar"
          link="https://api.whatsapp.com/send?phone=5562984983217"
          icon={SiWhatsapp}
        />

        <Card
          title="Visite nossa loja"
          link="https://maps.app.goo.gl/EmLdZtuYSLWu9AiU9?g_st=iw"
          icon={RiRoadMapFill}
        />
      </div>

    </div>
  );
}
