/* eslint-disable jsx-a11y/anchor-is-valid */
import { IconType } from 'react-icons';

interface CardProps {
  title: string;
  link: string;
  icon: IconType;
}

export function Card({ title, link, icon: Icon }: CardProps) {

  function handleOpenLink() {
    window.open(link, '_blank');
  }

  return (
    <div className='
        w-full bg-primary-500 flex items-center justify-center hover:bg-primary-600 cursor-pointer border-4 border-white rounded-full
        py-2.5 px-4 text-white text-xl 
        transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110
        hover:shadow-xl   
      '
      onClick={handleOpenLink}
    >
      <div className='flex items-center justify-between w-full px-3'>
        {Icon && <Icon className='w-6 h-6' />}
        <span className='mx-auto'>
          <a
            href="#"
            rel="noreferrer"
            className='text-white text-lg'
          >{title}</a>
        </span>
      </div>
    </div>
  )
}